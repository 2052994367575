<template>
  <div class="rankingSeason hidden-scrollbar">
    <div class="top"></div>
    <div class="body" v-if="dataList.length > 0">
      <div class="box" v-for="(item, index) in dataList" :key="index">
        <p class="box-title">{{ item.title }}</p>
        <p class="box-title" style="font-size: 12px">
          {{ item.subtitle || "" }}
        </p>
        <div class="box-top" v-if="item.data.length > 0">
          <div
            class="box-item"
            :class="el.className"
            v-for="(el, ids) in topThree"
            :key="'box-item:' + ids"
          >
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="el.icon"></use>
            </svg>
            <p class="bold">
              {{ item.data[el.index]?.name || "" }}
            </p>
            <p class="bold">
              {{ item.data[el.index]?.org_name || "" }}
            </p>
            <p v-if="item.data[el.index]?.type_basis">
              {{ item.data[el.index]?.type_basis + "：" || "" }}
            </p>
            <p v-if="item.data[el.index]?.type_basis">
              {{ item.data[el.index]?.count || "" }}
            </p>
            <p v-if="item.data[el.index]?.avg_amount">人均业绩：</p>
            <p v-if="item.data[el.index]?.avg_amount">
              {{ item.data[el.index]?.avg_amount || "" }}
            </p>
            <p v-if="item.data[el.index]?.avg_received">人均来单：</p>
            <p v-if="item.data[el.index]?.avg_received">
              {{ item.data[el.index]?.avg_received || "" }}
            </p>
            <p v-if="item.data[el.index]?.personnel_count">
              {{ `人数：${item.data[el.index]?.personnel_count || ""}` }}
            </p>
          </div>
        </div>
        <div class="box-other" v-if="item.data.length > 3">
          <p
            class="row one-line"
            v-for="(el, ids) in item.data.slice(3)"
            :key="'row' + ids"
            :title="el.org_name"
          >
            <span class="right">{{ ids + 4 }}</span>
            <span class="font-size-12 font-w-6">{{
              `${el.org_name}/${el.name} `
            }}</span>
            <span class="font-size-12">{{ el.count }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="none" v-if="dataList.length == 0">
      <p>暂无数据</p>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      dataList: [],
      topThree: [
        { index: 1, icon: "#icon-yinpai", className: "two" },
        { index: 0, icon: "#icon-guanjun", className: "one" },
        { index: 2, icon: "#icon-tongpai", className: "three" },
      ],
    });

    const activityDataList = () => {
      store
        .dispatch("actCzActivityDataList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          option: route.query.option as string,
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data_list) {
              dataMap.dataList = res.data_list;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    activityDataList();

    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.rankingSeason {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  .top {
    width: 375px;
    height: 410px;
    background-image: url("/static/images/season-top.gif");
    background-size: 375px 410px;
    background-repeat: no-repeat;
  }
  .body {
    width: 375px;
    background-image: url("/static/images/season-middle.jpg");
    background-size: 375px 10px;
    background-repeat: repeat-y;
    .box {
      padding: 20px 51px 0;
      .box-title {
        text-align: center;
        color: #ffcc66;
        font-size: 16px;
        font-weight: 700;
      }
      .box-top {
        display: flex;
        flex-wrap: nowrap;
        padding: 20px 0;
        .box-item {
          flex: 1;
          text-align: center;
          > p {
            color: #fff;
            font-size: 12px;
            & + p {
              padding-top: 5px;
            }
          }
          .bold {
            font-size: 16px !important;
            font-weight: 700 !important;
          }
        }
        .two,
        .three {
          padding-top: 30px;
        }
      }
      .box-other {
        border-top: 1px solid #fff;
        padding: 10px 0 20px 0;
        color: #fff;
        p {
          line-height: 35px;
          height: 35px;
          border-bottom: 1px solid #fff;
          span + span {
            margin-left: 10px;
          }
          .right {
            display: inline-block;
            width: 20px;
            text-align: right;
          }
        }
      }
      & + .box {
        padding-top: 50px;
      }
    }
  }
  .bottom {
    width: 375px;
    height: 127.5px;
    background-image: url("/static/images/season-bottom.gif");
    background-size: 375px 127.5px;
    background-repeat: no-repeat;
  }
  .none {
    min-height: calc(100vh - 537.5px);
    width: 375px;
    background-image: url("/static/images/season-middle.jpg");
    background-size: 375px 10px;
    background-repeat: repeat-y;
    p {
      text-align: center;
      color: #fff;
      font-size: 18px;
      padding-top: 40px;
      font-weight: 700;
    }
  }
}
.icon {
  width: 35px;
  height: 35px;
}
</style>